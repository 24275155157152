import React from "react"
import InsightSideBar from '../components/insightSideBar';
import InsightAwareness from '../components/InsightAwareness';
import InsightCarousel from '../components/InsightCarousel';
import InsightItemList from '../components/insightList';
import Layout from "../components/layout";
import SEO from "../components/seo";

const insights = () => {

  return (
    <Layout>
    <SEO
    title="iQubeLabs | Insights"
    description="We are passionate about nurturing talents to become super professionals. We encourage continuous improvement and applied innovations."
    />
      
        <InsightSideBar/>
        <InsightCarousel/>
        <InsightItemList />
      
      {/* <InsightAwareness/> */}
    </Layout>
  )
}

export default insights
