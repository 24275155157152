import React from "react"
import { Link } from "gatsby"
import styles from "./insightItemList.module.scss"
import { StaticQuery, graphql } from "gatsby"
import moment from "moment"

const InsightItem = props => {
    console.log(props)
  return (
    <Link className={styles.link} to={`/insights/${props.slug}`}>
      <div className={styles.image}>
        <img src={props.image} alt={props.title} />
      </div>
      <div className={styles.content}>
        <div>
          {props.tags.map(tag => (
            <span>{tag}</span>
          ))}
          <h4>{props.title}</h4>
          <p>{props.description}</p>
        </div>
        <div className={styles.blogInfo}>
          <p>
          {moment(props.publishDate).format("MMMM Do YYYY")} | {props.author}
          </p>
        </div>
      </div>
    </Link>
  )
}

const InsightItemList = ({ data }) => {
  const insightData = data.allContentfulInsights.edges
  console.log(data)
  return (
    <div className={styles.container}>
      <h4>All Post</h4>
      {insightData.map(insight => (
        <InsightItem
          slug={insight.node.slug}
          title={insight.node.title}
          tags={insight.node.tags}
          date={insight.node.publishDate}
          image={insight.node.heroImage.file.url}
          description={insight.node.description.description}
          author={insight.node.author.name}
          body={insight.node.postBody.json}
        />
      ))}
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query InsightItem{
        allContentfulInsights {
          edges {
            node {
              tags
              title
              slug
              publishDate
              description {
                description
              }
              postBody{
                json
              }
              heroImage {
                file {
                  url
                }
              }
              author {
                name
              }
            }
          }
        }
      }
    `}
    render={data => <InsightItemList data={data} {...props} />}
  />
)
