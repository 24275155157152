import React from "react";
import styles from './insightSideBar.module.scss';

const insightSideBar = () => {
  

  return (
      
    <div className={styles.sideBar}>
      <h3>Tech Today</h3>
      <p>Best emerging tech articles to catch up on weekly</p>
    </div>
  )
}

export default insightSideBar;
