import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { useStaticQuery, graphql,Link } from "gatsby"
import styles from './insightCarousel.module.scss'

import { Carousel } from "react-responsive-carousel"

const InsightCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
        allContentfulInsights(sort: { fields: publishDate, order: DESC }) {
        edges {
          node {
            title
            id
            description {
              description
            }
            heroImage {
              file {
                url
              }
            }

            slug
          }
        }
      }
    }
  `)

  

  return (
    <div className={styles.container}>
      <h3>
        Latest Post
      </h3>
      <Carousel className={styles.carousel} showThumbs={false} showIndicators={false} showStatus={false} autoPlay>
        {data.allContentfulInsights.edges.map(edge => {
          return (
            <div className={styles.motionPictures}>
              <div style={{background:`url(${edge.node.heroImage.file.url})`,  height: "100%", backgroundSize: "cover"}}></div>
              <Link className={styles.link} to={edge.node.slug}>
                <p>{edge.node.description.description}</p>
              </Link>
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

export default InsightCarousel
